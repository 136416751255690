import React, { Component } from 'react';

class Footer extends Component {
   render() {

      let data = this.props.data;
      let logoAttr = 'Academia Station';
      let publicUrl = process.env.PUBLIC_URL
      let logoLink = publicUrl;
      return (

         <footer id="footer" className="p-70px-t p-30px-b footer featured-2">
            <div className="container">
               <div className="row">
                  <div className="col-sm-12">
                     <div className="footer-top text-center p-30px-tb">
                        <a className="footer-logo" href="/"><img src={process.env.PUBLIC_URL + '/assets/img/logo/academia_transparent_black_full_white_logo.png'} alt={logoAttr} /></a>
                        <p>{data.footertext}</p>

                        {/* <div className="social-network">
                           {data.socialicon.map((data, i) => {
                              return <a key={i} href={data.url}><i className={` social-icon ${data.icon} `}></i></a>
                           })}
                        </div> */}

                     </div>
                     <div className="footer-copyright p-30px-tb text-center">
                        <p dangerouslySetInnerHTML={{ __html: data.copyrighttext }}></p>
                     </div>
                  </div>
               </div>
            </div>
         </footer>

      );
   }
}

export default Footer;
